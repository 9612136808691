// --------------------------------------------------------------------------
// application.js
// --------------------------------------------------------------------------
// This file imports and initialises modules.
//
// Add module names to the modules array for automatic initialisation.
//
// No specific javascript should be placed in this file.
// --------------------------------------------------------------------------

$(function() {

  'use strict';

  FastClick.attach(document.body);

  $("[data-fancybox]").fancybox();

  if (window.matchMedia("(min-width: 1025px)").matches) {
    skrollr.init({
      forceHeight: false
    });
  }

  var modules = ['maps', 'nav', 'homeBanner', 'DateAndTime'];

  for(var i = 0; i < modules.length; i++) {
    DG[modules[i]].init();
  }

});