DG.nav = function() {

  'use strict';

  function init() {
    bh('nav-toggle').on('click', toggleNav);
  }

  function toggleNav() {
    $('html').toggleClass('no-overflow');
    bh('nav-toggle').toggleClass('hamburger--active');
    $('.navigation').toggleClass('navigation--open');
  }

  return {
    init: init
  };

}();
