DG.DateAndTime = function() {

  'use strict';

  function init() {
    $('#date-field').pickadate({
      container: 'body'
    });
    $('#time-field').pickatime({
      container: 'body',
      clear: '',
      min: [12,0],
      max: [21,30]
    });
  }


  return {
    init: init
  };

}();
