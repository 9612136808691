$(function($){
  'use strict';

  $.fn.lightbox = function(params){
    var defaults = {
      url: false,
      section: '',
      class: 'lightbox',
      closeKey: 27,
      onComplete: false,
    };

    var options = $.extend(defaults, params),
        button = this,
        $body = $('body'),
        $html = $('html'),
        $window = $(window),
        lightbox = $('.' + options.class),
        lightboxInner = $('.' + options.class + " .lightbox__inner");

    if(lightbox.length <= 0){
      lightbox = $('<div></div>').addClass(options.class).appendTo($body);
      lightboxInner = $('<div></div>').addClass('lightbox__inner').appendTo(lightbox);
    }

    if(options.url){
      loadContent();
    }

    function loadContent(){
      $.ajax({
        url: options.url,
        success: function(data){
          var section = $(data).find(options.section);
          if (section.length <= 0) {
            console.log('true')
            var section = $(data).closest(options.section);
          }

          lightboxInner.html(section);
          closeBuild();
          $html.addClass('lightbox-open');
          onComplete();
        }
      });
    }

    function closeBuild(){
      var closeButton = $('<button />').addClass('lightbox__close').appendTo(lightboxInner).on('click', close);
      $window.on('keyup', function(e){
        if(e.keyCode === options.closeKey){
          close();
        }
      });
    }

    function close(){
      $html.removeClass('lightbox-open');
    }

    function onComplete(){
      if(typeof options.onComplete === 'function'){
        options.onComplete.call();
      }
    }

    return {
      close: close
    }
  };   
}(jQuery));