DG.homeBanner = function() {

  'use strict';

  function init() {
    var slider = bh("home-banner");
    
    slider.slick( {
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: false,
      infinite: true,
      focusOnSelect: true
    });
  }

  return {
    init: init
  };

}();