DG.maps = function(){

  var mapEl = bh('map-canvas'),
      map,
      location = new google.maps.LatLng(mapEl.data('lat'), mapEl.data('lng')),
      mapOptions = {
        center: location,
        zoom: 15,
        disableDefaultUI: true,
        scrollwheel: false
      };

  function init() {
    if(mapEl.length > 0){
      google.maps.event.addDomListener(window, 'load', create);
    }
  }

  function create(){
    map = new google.maps.Map(mapEl[0], mapOptions);
    var marker = new google.maps.Marker({
      position: location,
      map: map
    });
    panBy();

    resizer();
  }

  function resizer(){
    var timer;
    $(window).on('resize', function(){
      if(timer){
        clearTimeout(timer);
      }

      timer = setTimeout(move, 500);
    });
  }

  function panBy(){
    if(window.matchMedia("(max-width: 767px)").matches){
      map.panBy(50, -200);
    } else {
      map.panBy(300, -50);
    }
  }

  function move(){
    map.panTo(location);
    panBy();
  }

  return {
    init: init,
    move: move
  }

}();
